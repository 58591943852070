import React from "react";
import { Link } from "gatsby";
import HowToLayout from "../../../components/how-to-layout";

const Overview = () => {
  return (
    <HowToLayout>
      <h2>Overview</h2>
      <section name="Testing Name" id="1" style={{ height: 600 }}>
        TESTING THIS
      </section>
      <section name="Testing Name 2" id="2" style={{ height: 600 }}>
        TESTING THIS 2
      </section>
      <section name="Testing Name 3" id="3" style={{ height: 600 }}>
        TESTING THIS 3
      </section>
      <section name="Testing Name 4" id="4" style={{ height: 600 }}>
        TESTING THIS 4
      </section>
    </HowToLayout>
  );
};

export default Overview;
